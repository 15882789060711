import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import WebinarRegistrationForm from '../components/partials/WebinarRegistrationForm'

import Logos from '../components/partials/Logos9'

import ogImage from '../resources/images/logos/featured_image.jpg'
import { StaticImage } from 'gatsby-plugin-image'

class webinar extends React.Component {
    render() {
        let video
        let form

        // Create Date format
        const tempDate: any = get(this.props, 'data.contentfulWebinar.date')
        const date = new Date(tempDate)
        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        } as const

        const webinar: any = get(this.props, 'data.contentfulWebinar')
        let isRegistered
        if (typeof window !== 'undefined') {
            isRegistered = localStorage.getItem('webinarRegistered')
        }
        if (isRegistered == 'true') {
            video = (
                <div id='webinar-video' className='videoWrapper pt-5'>
                    <iframe
                        width='560'
                        height='300'
                        src={`https://www.youtube.com/embed/${webinar?.videoId}`}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        allowFullScreen></iframe>
                </div>
            )
        } else {
            form = (
                <div className='col-md-3'>
                    <div id='webinar-form' className='webinar-form-border-left'>
                        <WebinarRegistrationForm />
                    </div>
                </div>
            )
        }
        return (
            <Layout>
                <Helmet>
                    <title>{webinar?.title} | Lenses.io</title>
                    <meta name='twitter:text:title' content={webinar?.title} />
                    <meta name='description' content={webinar?.description} />
                    <meta property='og:description' content={webinar?.description} />
                    <meta property='twitter:description' content={webinar?.description} />

                    <meta name='keywords' content={webinar?.keywords.keywords} />

                    <meta name='twitter:card' content='summary_large_image' />
                    <meta name='twitter:site' content='@lensesio' />
                    <meta property='og:title' content={webinar?.title} />
                    <meta property='twitter:title' content={webinar?.title} />
                    <meta property='og:image' content={ogImage} />
                    <meta property='twitter:image' content={ogImage} />
                </Helmet>
                <StaticImage
                    className='home-fixed-top'
                    src='../resources/images/homepage/hero_lens.svg'
                    placeholder='blurred'
                    alt='Hero Lenses'
                />
                <section className='pt-5 mt-5 pb-5'>
                    <div className='container-1'>
                        <div className='row mt-3'>
                            <div className='col-md-6 pt-0'>
                                <p className='title breadcrumb-text pt-4'>
                                    <a href='/resources/' className='title_text'>
                                        RESOURCES
                                    </a>{' '}
                                    /
                                    <a href='/webinars/' className='title_text font-weight-bold'>
                                        WEBINARS
                                    </a>
                                </p>
                                <h1>{webinar?.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section id='metadata' className='webinar-metadata-border-bottom'>
                    <div className='container-1'>
                        <div className='row'>
                            <div className='col-md-2'>
                                <i className='fa fa-calendar-o pr-2' aria-hidden='true'></i>
                                <span>{date.toLocaleDateString('en-US', dateOptions)}</span>
                            </div>
                            <div className='col-md-2'>
                                <i className='fa fa-clock-o pr-2' aria-hidden='true'></i>
                                {webinar?.duration} {' min'}
                            </div>
                            <div className='col-md-4'>
                                <i className='fa fa-user pr-2' aria-hidden='true'></i>
                                {webinar?.author.name}
                            </div>
                        </div>
                    </div>
                </section>

                <section id='summary' className='pt-5 pb-5'>
                    <div className='container-1'>
                        <div className='row'>
                            <div className='col-md-9'>
                                {video}
                                <div id='webinar-summary' className='mt-3'>
                                    {renderRichText(webinar?.content)}
                                </div>
                            </div>
                            {form}
                        </div>
                    </div>
                </section>
                <section>
                    <div className='border-top-0 border-bottom-light bg-white py-5'>
                        <div className='container-1'>
                            <h4 className='text-center'>TRUSTED BY OUR CLIENTS </h4>

                            <div className='row'>
                                <Logos />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mobile-margin-bottom mobile-margin-top my-5'>
                    <div className='container-1 bg-dark rounded-1 p-5'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md-12 col-12 text-center roll-button'>
                                <p className='mb-5 paragraph-title text-white'>
                                    Ready to get started with Lenses?{' '}
                                </p>
                                <a className='bg-white primary-text' href='/start/'>
                                    <span data-hover='Download free'>Download free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default webinar

export const webinarQuery = graphql`
    query WebinarBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulWebinar(slug: { eq: $slug }) {
            slug
            title
            videoId
            date
            description
            duration
            keywords {
                keywords
            }
            content {
                raw
            }
            author {
                name
            }
        }
    }
`
